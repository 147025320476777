import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ApiParamsBulkGuestReSendEmail, EmailTemplate, PaginationMetaData, TemplateEmailSummary } from 'types';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar, Col, Form, InputGroup, ProgressBar, Row, Spinner, Table } from 'react-bootstrap';
import { EmailDeliveryStatuses, EmailTemplateStatuses } from 'constant';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { apiDownloadEmailReport, apiEmailReport, apiEmailSummaryReport } from 'api/emailTemplate';
import { apiBulkReSendEmailGuest } from 'api/guests';
import { toast } from 'react-toastify';


const EmailTemplateReport = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { state }: { state: EmailTemplate | null } = useLocation()
	const { templateId } = useParams();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [lastCheck, setLastCheck] = useState<string>('');
	const [reportSummary, setReportSummary] = useState<TemplateEmailSummary>({
		total: 0,
		pending: 0,
		request: 0,
		invalid_email: 0,
		missing_emails: 0,
		delivered: 0,
		unique_opened: 0,
		click: 0,
		soft_bounce: 0,
		hard_bounce: 0,
		error: 0,
		deferred: 0,
		spam: 0,
		unsubscribed: 0,
		blocked: 0,
	})

	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(20);
	const [orderBy, setOrderBy] = useState<string>('updated_at');
	const [orderType, setOrderType] = useState<string>('DESC');
	const [filterBy, setFilterBy] = useState<string>('');
	const [reportEmails, setReportEmails] = useState<any[]>([]);
	const [reportEmailsMeta, setReportEmailsMeta] = useState<{
		page: number
		limit: number
		total: number
		total_pages: number
	}>({
		page: 1,
		limit: 20,
		total: 0,
		total_pages: 0,
	});
	const [totalPages, setTotalPages] = useState<number[]>([]);

	const [allRowSelected, setAllRowSelected] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const [sendingEmails, setSendingEmails] = useState<boolean>(false);
	const [refreshingReport, setRefreshingReport] = useState<boolean>(false);


	const template = state;
	// console.log({ templateId, state })

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Email Templates'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			await getReport();
		})()

		const checkInterval = setInterval(getReport, (1000 * 60))

		return () => {
			if (checkInterval) {
				clearInterval(checkInterval)
			}
		}
	}, [page, limit, orderBy, orderType, filterBy])

	const getReport = async () => {
		console.log('getReport')
		if (activeEvent && templateId) {
			batch(() => {
				setRefreshingReport(true)
				setLastCheck(dayjs().format('hh:mm A'))
			})
			const summaryResponse = await apiEmailSummaryReport(activeEvent.id, templateId)

			if (summaryResponse.success) {
				setReportSummary(summaryResponse.data)
			}

			const emailsResponse = await apiEmailReport(activeEvent.id, templateId, {
				page,
				limit,
				orderBy,
				orderType,
				q: filterBy
			})

			if (emailsResponse.success) {
				batch(() => {
					setRefreshingReport(false)
					setReportEmails(emailsResponse.data.items)
					setReportEmailsMeta(emailsResponse.data.meta_data)
					setTotalPages([...new Array(Math.floor(reportEmailsMeta.total_pages)).fill(1).map((_, i) => (i+1)).filter(p => {
						return !(p >= (page + 3) || p <= (page - 3))
					})])
				})
			} else {
				setRefreshingReport(false)
			}
		}
	}

	const changeOrder = (type: string) => {
		if (orderBy === type) {
			setOrderType(orderType === 'DESC' ? 'ASC' : 'DESC')
		} else {
			setOrderBy(type)
			setOrderType('ASC')
		}
	}

	const downloadList = async () => {
		if (activeEvent && templateId) {
			const response = await apiDownloadEmailReport(activeEvent.id, templateId);

			if (response.success) {
				window.open(response.data, '_blank');
			}
		}
	}

	const handleReSendEmails = async () => {
		if (!allRowSelected && selectedRows.length === 0) {
			return;
		}

		if (activeEvent && templateId) {
			const data: ApiParamsBulkGuestReSendEmail = {
				templateId: templateId,
				allEmails: allRowSelected ? 1 : 0,
				emailIds: (allRowSelected) ? null : selectedRows,
			}
	
			setSendingEmails(true)
			const response = await apiBulkReSendEmailGuest(activeEvent.id, data)
			setSendingEmails(false)
			if (response.success) {
				toast.success(response.message)
				batch(() => {
					setAllRowSelected(false);
					setSelectedRows([]);
					getReport()
				});
			} else {
				toast.error(response.message)
			}
		}
	}

	if (!activeEvent || !template) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="page-email-template-report mb-5">

				<Row className='mb-4'>
					<Col>
						<Link to={`/email-templates`} className='btn btn-white'>Back</Link>
					</Col>
					<Col className='text-center'>
						<h6 className='mb-0'>
							Real-time Report: <span className='text-success'>{lastCheck}</span>
							<Button type='button' variant='white' className='ms-2' onClick={getReport} disabled={refreshingReport} style={{ minWidth: '83px' }}>
								{refreshingReport ? (
									<>
									<Spinner animation="border" role="status" size='sm'>
										<span className="visually-hidden">Loading...</span>
									</Spinner>
									</>
								) : 'Refresh'}
							</Button>
						</h6>
					</Col>
					<Col></Col>
				</Row>
				

				<div className="page-card card">
					<div className="card-header bg-white p-3">
						<Row style={{ alignItems: 'center' }}>
							<Col><h5 className='m-0'>{template?.name}</h5></Col>
							<Col>
								<div
									className={`status-badge ${(template.status === EmailTemplateStatuses.ACTIVE) ? 'active' : ''} ${(template.status === EmailTemplateStatuses.DRAFT) ? 'testing' : ''} ${(template.status === EmailTemplateStatuses.ARCHIVED) ? 'archived' : ''} `}
									style={{ margin: '0 auto' }}
								>
									<span className="dot"></span>
									<span className="label">
										{template.status === EmailTemplateStatuses.ACTIVE ? 'Active' : template.status === EmailTemplateStatuses.DRAFT ? 'Draft' : 'Archived'}
									</span>
								</div>
							</Col>
							<Col className='text-end'>
								<Link to={`/email-templates/${template.id}/preview`} className='btn btn-white'>View Template</Link>
							</Col>
						</Row>
					</div>
					<div className="card-body">
						{errMsg && <div className="alert alert-danger">{errMsg}</div>}

						<div><strong>Email Subject:</strong></div>
						<p>{template.subject}</p>

						<p><strong>Sender Name:</strong> {template.sender_name}</p>
						<Row>
							<Col md={4}><p><strong>Sender Email:</strong> {template.sender_email}</p></Col>
							<Col><p><strong>Reply-to Email:</strong> {template.sender_reply_emails}</p></Col>
						</Row>


						<Row>
							<Col md={{
								span: 10,
								offset: 1,
							}}>

								<div className='mt-4'>
									<h2 style={{
										fontWeight: 100,
									}}>{reportSummary.delivered + reportSummary.unique_opened + reportSummary.click} Delivered <span style={{
										fontSize: '1rem',
										color: '#777'
									}}>of {reportSummary.total} Recipients</span></h2>
								</div>


								<div className="mt-2 progress">
									<ProgressBar
										max={reportSummary.total}
										min={0}
										isChild={true}
										now={reportSummary.delivered + reportSummary.unique_opened + reportSummary.click}
										style={{ backgroundColor: 'rgb(109, 166, 128)' }}
									></ProgressBar>
								</div>
								<div className="mt-2 progress">
									<ProgressBar
										max={reportSummary.total}
										min={0}
										isChild={true}
										now={reportSummary.unique_opened + reportSummary.click}
										style={{ backgroundColor: 'rgb(77, 121, 91)' }}
									></ProgressBar>
								</div>
								<div className="mt-2 progress">
									<ProgressBar
										max={reportSummary.total}
										min={0}
										isChild={true}
										now={reportSummary.click}
										style={{ backgroundColor: 'rgb(55, 89, 66)' }}
									></ProgressBar>
								</div>

								<div className='mt-3 email-status-row'>
									<div className='pending'>Pending (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('pending')}>{reportSummary.pending}</Button>)</div>
									<div className='pending'>Request (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('request')}>{reportSummary.request}</Button>)</div>
									<div className='delivered'>Delivered (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('delivered')}>{reportSummary.delivered + reportSummary.unique_opened + reportSummary.click}</Button>)</div>
									<div className='opened'>Opened (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('unique_opened')}>{reportSummary.unique_opened + reportSummary.click}</Button>)</div>
									<div className='clicked'>Clicked (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('click')}>{reportSummary.click}</Button>)</div>
									<div className='invalid_email'>Invalid Email (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('invalid_email')}>{reportSummary.invalid_email}</Button>)</div>
									<div className='missing_email'>Missing Email (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('missing_email')}>{reportSummary.missing_emails}</Button>)</div>
								
									<div className='not_delivered'>Soft Bounce (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('soft_bounce')}>{reportSummary.soft_bounce}</Button>)</div>
									<div className='not_delivered'>Hard Bounce (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('hard_bounce')}>{reportSummary.hard_bounce}</Button>)</div>
									<div className='not_delivered'>Error (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('error')}>{reportSummary.error}</Button>)</div>
									<div className='not_delivered'>Deferred (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('deferred')}>{reportSummary.deferred}</Button>)</div>
									<div className='not_delivered'>Spam (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('spam')}>{reportSummary.spam}</Button>)</div>
									<div className='not_delivered'>Unsubscribed (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('unsubscribed')}>{reportSummary.unsubscribed}</Button>)</div>
									<div className='not_delivered'>Blocked (<Button type='button' variant='link' size='sm' onClick={() => setFilterBy('blocked')}>{reportSummary.blocked}</Button>)</div>
								</div>
							</Col>
						</Row>


						<div className="mt-5">
							<div className="report-filters">
								<Row>
									<Col md={3}>
										<InputGroup className="mb-3">
											<InputGroup.Text id="filter-by">Filter</InputGroup.Text>
											<Form.Select value={(filterBy === '') ? 'all' : filterBy} onChange={e => {
												if (e.target.value === 'all') {
													setFilterBy('')
												} else {
													setFilterBy(e.target.value)
												}
											}}>
												<option value="all">All Guests</option>
												{(Object.keys(EmailDeliveryStatuses).map(k => {
													return (<option key={k} value={k}>{EmailDeliveryStatuses[k]}</option>)
												}))}
											</Form.Select>
										</InputGroup>
									</Col>
									<Col md={2}>
										<Button type='button' variant='dark'
											disabled={!(allRowSelected || selectedRows.length) || sendingEmails}
											onClick={handleReSendEmails}
										>Resent Email {(allRowSelected) ? `(${reportEmailsMeta.total})` : selectedRows.length ? `(${selectedRows.length})` : ''}</Button>
									</Col>
									<Col className='text-end'>
										<Button type='button' variant='dark' onClick={downloadList}>Download Report</Button>
									</Col>
								</Row>
							</div>

							<Table responsive bordered hover >
								<thead>
									<tr>
										<th className='text-center ps-4'>
											<Form.Group>
												<Form.Check id="check-all" className="" type="checkbox" name="check-all" checked={allRowSelected} label="" onChange={() => setAllRowSelected(!allRowSelected)} />
											</Form.Group>
										</th>
										<th onClick={() => changeOrder('updated_at')}>
											Latest Activity
											{(orderBy === 'updated_at') ? (
												<>
												{(orderType === 'ASC') ? (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#1C274C"/>
														<path d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z" fill="#1C274C"/>
													</svg>
												) : (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z" fill="#1C274C"/>
														<path d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z" fill="#1C274C"/>
													</svg>
												)}
												</>
											) : null}
										</th>
										<th onClick={() => changeOrder('guest_name')}>
											Name
											{(orderBy === 'guest_name') ? (
												<>
												{(orderType === 'ASC') ? (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#1C274C"/>
														<path d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z" fill="#1C274C"/>
													</svg>
												) : (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z" fill="#1C274C"/>
														<path d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z" fill="#1C274C"/>
													</svg>
												)}
												</>
											) : null}
										</th>
										<th onClick={() => changeOrder('email')}>
											Email
											{(orderBy === 'email') ? (
												<>
												{(orderType === 'ASC') ? (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#1C274C"/>
														<path d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z" fill="#1C274C"/>
													</svg>
												) : (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z" fill="#1C274C"/>
														<path d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z" fill="#1C274C"/>
													</svg>
												)}
												</>
											) : null}
										</th>
										<th onClick={() => changeOrder('status')}>
											Status
											{(orderBy === 'status') ? (
												<>
												{(orderType === 'ASC') ? (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#1C274C"/>
														<path d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z" fill="#1C274C"/>
													</svg>
												) : (
													<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z" fill="#1C274C"/>
														<path d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z" fill="#1C274C"/>
														<path opacity="0.7" d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z" fill="#1C274C"/>
														<path opacity="0.4" d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z" fill="#1C274C"/>
													</svg>
												)}
												</>
											) : null}
										</th>
									</tr>
								</thead>
								<tbody>
									{(reportEmails.length === 0) ? (
										<tr>
											<td colSpan={5} className='text-muted text-center'>No records available...</td>
										</tr>
									) : null}
									{reportEmails.map(email => {
										return (
											<tr
												key={email.id}
												className={`${(allRowSelected || selectedRows.includes(email.id)) ? 'selected' : ''}`}
											>
												<td className='text-center ps-4' >
													<Form.Group>
														<Form.Check
															id={`check-${email.id}`}
															className=""
															type="checkbox"
															name={`check-${email.id}`}
															label=""
															checked={allRowSelected || selectedRows.includes(email.id)}
															onChange={() => {
																if (selectedRows.includes(email.id)) {
																	setSelectedRows([...selectedRows.filter(id => id !== email.id)])
																} else {
																	setSelectedRows([...selectedRows, email.id])
																}
															}}
														/>
													</Form.Group>
												</td>
												<td>{email.updated_at_formatted}</td>
												<td>{(email.guest_name) ? email.guest_name : `${email.first_name || ''} ${email.last_name || ''}`}</td>
												<td>
													{email.email} {(email.type && email.type === 'TEST_TEMPLATE_EMAIL') ? (
														<span className='small text-muted'>- (Test Email)</span>
													) : null}
													</td>
												<td>{(email.is_sent === 0 && email.is_failed === 0) ? 'Pending' : (EmailDeliveryStatuses.hasOwnProperty(email.status) ? EmailDeliveryStatuses[email.status] : email.status)}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>

							<Row>
								<Col>
								<p className='text-muted'>Page {page} of {reportEmailsMeta.total_pages}</p>
								</Col>
								<Col>
								{(reportEmailsMeta.total > 20) ? (
									<ButtonToolbar aria-label="Toolbar with button groups" style={{justifyContent: 'flex-end'}}>
										<ButtonGroup>
											{(page > 1) ? (
												<Button
													type='button'
													variant='dark'
													onClick={() => setPage(page - 1)}
												>Prev</Button>
											) : null}
											{(!totalPages.includes(1)) ? (
												<Button
													type='button'
													variant='dark'
													onClick={() => setPage(1)}
												>...</Button>
											) : null}
											{totalPages.map((i) => {
												return (
													<Button key={i}
														type='button'
														variant='dark'
														active={(i) === page}
														onClick={() => setPage(i)}
													>{i}</Button>
												)
											})}
											{(!totalPages.includes(reportEmailsMeta.total_pages)) ? (
												<Button
													type='button'
													variant='dark'
													onClick={() => setPage(reportEmailsMeta.total_pages)}
												>...</Button>
											) : null}
											{(page < reportEmailsMeta.total_pages) ? (
												<Button
													type='button'
													variant='dark'
													onClick={() => setPage(page + 1)}
												>Next</Button>
											) : null}
										</ButtonGroup>
									</ButtonToolbar>
								) : null}
								</Col>
							</Row>
						</div>
					</div>
				</div>


			</div>
		</>
	)
}

export default EmailTemplateReport;