import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EmailTemplate } from 'types';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { apiGetBeeFreeToken, apiGetTemplatePreview, apiUploadTemplate, saveBuilderData } from 'api/emailTemplate';
import BeePlugin from '@mailupinc/bee-plugin'
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { EmailTemplateStatuses } from 'constant';
import { apiGetGuestsByEvent } from 'api/guests';
import TemplatePlaceholderPopup from './TemplatePlaceholderPopup';
import TestEmailPopup from './TestEmailPopup';
import TemplateEditPopup from './TemplateEditPopup';
import { toast } from 'react-toastify';


const EmailTemplateEmailBuilder = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { templateId } = useParams();
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const userData = useAppSelector(state => state.auth.userData);
	const [template, setTemplate] = useState<EmailTemplate | null>();
	const [placeholderPopupOpen, setPlaceholderPopupOpen] = useState<boolean>(false);
	const [isEditTemplate, setIsEditTemplate] = useState<EmailTemplate | null>(null);
	const [testEmailPopupOpen, setTestEmailPopupOpen] = useState<boolean>(false);
	const BeeEditor = useRef<BeePlugin | null>(null);


	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Email Templates'))
		})
	}, [])

	useEffect(() => {

		(async () => {
			if (activeEvent && templateId) {
				batch(() => {
					setErrMsg('')
					setIsLoading(true);
				})
				const response = await apiGetTemplatePreview(activeEvent.id, templateId, null)
				setIsLoading(false);
				if (response.success) {
					setTemplate(response.data)
				}
				else {
					setErrMsg(response.message)
				}
			}
		})();

	}, [activeEvent, templateId])

	useEffect(() => {
		(async () => {
			if (userData && template && template.template_json) {

				if (BeeEditor.current === null) {
					const beeConfig = {
						// uid: 'test1-clientside', //needed for identify resources of the that user and billing stuff
						uid: `client-${userData.id}`,
						container: 'bee-sdk-container', // Identifies the id of div element that contains the Beefree SDK builder
						language: 'en-US',
						onSave: onSave,
						onSaveAsTemplate: onSaveAsTemplate,
						onSend: onSend,
						onError: onError,
					}
					const response = await apiGetBeeFreeToken()
			
					if (response.success) {
						BeeEditor.current = new BeePlugin(response.data)
						BeeEditor.current.start(beeConfig, template.template_json)
					}
				} else {
					BeeEditor.current.load(template.template_json)
				}
			}
		})()
	}, [template])
	

	const onTemplateUpdate = (updatedTemplate: EmailTemplate) => {
		setIsEditTemplate(null);
		if (template) {
			setTemplate({
				...template,
				status: updatedTemplate.status,
				name: updatedTemplate.name,
				sender_name: updatedTemplate.sender_name,
				sender_email: updatedTemplate.sender_email,
				sender_reply_emails: updatedTemplate.sender_reply_emails,
				subject: updatedTemplate.subject,
			})
		}
	}

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	const onSave = async (jsonFile: any, htmlFile: any) => {
		console.log('onSave::', typeof jsonFile, htmlFile)

		if (activeEvent && templateId) {
			const response = await saveBuilderData(activeEvent.id, templateId, jsonFile, htmlFile)

			if (response.success) {
				toast.success(response.message)
			}
		}
	}

	const onSaveAsTemplate = (jsonFile: any) => {
		console.log('onSaveAsTemplate', jsonFile)
	}
	
	const onSend = (htmlFile: any) => {
		console.log('onSend', htmlFile)
		if (BeeEditor.current) {
			BeeEditor.current.save();
			setTestEmailPopupOpen(true)
		}
	}

	const onError = (errorMessage: any) => {
		console.log('onError ', errorMessage)
	}



	return (
		<>
			<div className="page-guest-list-view">
				<div className="page-card card">
					<div className="card-header bg-white p-3">
						<Row className='mb-2'>
							<Col>
								<Link to={`/email-templates`} className='btn btn-white'><span className='ms-3 me-3'>Back</span></Link>
							</Col>
							<Col className='text-end'>
								<Button variant="dark" className='me-2'
								onClick={() => setTestEmailPopupOpen(true)}
								>
								<span className='ms-3 me-3'>Send Test</span>
								</Button>

								{(template && template.status !== EmailTemplateStatuses.ACTIVE) ? (
									<Button variant="outline-success" 
										onClick={() => setIsEditTemplate(template)}
									>
									<span className='ms-3 me-3'>Activate</span>
									</Button>
								) : null}
							</Col>
						</Row>
						<Row className='align-items-end mt-3'>
							<Col>
								<p className='text-muted m-0'>Email template name</p>
								<h5 className='m-0'>{template?.name}</h5>
							</Col>
							<Col>
								
							</Col>
							<Col className='text-end'>
								<Button 
									type='button' 
									variant='link' 
									className='text-dark'
									onClick={() => setPlaceholderPopupOpen(true)}
								>View Placeholder</Button>
								
								<Link to={`/email-templates/${template?.id}/preview`} state={template} className='btn btn-link text-dark' >Preview</Link>
							</Col>
						</Row>
					</div>
					<div className="card-body">
						{errMsg && <div className="alert alert-danger">{errMsg}</div>}
						
						<div id='bee-sdk-container' style={{
							height: 800
						}}></div>
					</div>
				</div>


				{(placeholderPopupOpen) && (
					<TemplatePlaceholderPopup eventId={activeEvent.id} isOpen={placeholderPopupOpen} setIsOpen={setPlaceholderPopupOpen} />
				)}

				{(testEmailPopupOpen && template) && (
					<TestEmailPopup template={template} eventId={activeEvent.id} isOpen={testEmailPopupOpen} setIsOpen={setTestEmailPopupOpen} />
				)}

				{(isEditTemplate) && (
					<TemplateEditPopup template={isEditTemplate} eventId={activeEvent.id} isOpen={isEditTemplate !== null} setIsOpen={setIsEditTemplate} onUpdate={onTemplateUpdate} />
				)}
			</div>
		</>
	)
}

export default EmailTemplateEmailBuilder;