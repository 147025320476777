import React, { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// https://www.npmjs.com/package/@yudiel/react-qr-scanner
import { QrScanner } from '@yudiel/react-qr-scanner';
import { toast } from 'react-toastify';
import { Guest, GuestWithList, ResponseObject, ScannerProps } from 'types';
import { setShowScanner } from 'redux/interfaceManger';
import { Button, Col, Form, Image, Modal, Offcanvas, ProgressBar, Row } from 'react-bootstrap';
import { ScanTypes } from 'constant';
import { apiSearchAndCheckedInGuest, apiSearchAndTrackGuest } from 'api/guests';
import { batch } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';

type GuestDetailsPopupProps = {
	guestDetails: ResponseObject<GuestWithList> | null
	onClose: () => void
	openGuestDetails: (id: number) => void
}

const initialPopupTimeout = 5; // sec
let waitForNextScan: boolean = false;


const GuestDetailsPopup = ({guestDetails, onClose, openGuestDetails}: GuestDetailsPopupProps) => {
	const [timer, setTimer] = useState<number>(initialPopupTimeout);

	useEffect(() => {
		setTimer(initialPopupTimeout);
		const interval = setInterval(() => {
			setTimer((prev) => {
				const count = prev - 1;
				if (count < 0) {
					clearInterval(interval);
					onClose();
				}
				return count;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [guestDetails]);

	if (!guestDetails) {
		return <></>
	}


	return (
		<Modal show={true} className="scannerGuestDetailsPopup" onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='m-0'>Guest Checked-in</h5>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				
				{guestDetails.success ? (
					<div>
						<div className='d-flex align-items-center justify-content-start gap-4 ps-3 pe-3 mb-3'>
							<div>
								<svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path 
										fill-rule="evenodd" 
										clip-rule="evenodd" 
										d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" 
										fill="#198754"
									/>
								</svg>
							</div>
							<h4 className='m-0 text-muted'>{guestDetails.message}</h4>
						</div>
						<div 
							style={{ 
								backgroundColor: 'rgba(25, 135, 84, 0.4)',
								borderRadius: 0,
								height: 6,
							}}
						>
							<ProgressBar
								max={100}
								min={0}
								now={((timer / initialPopupTimeout) * 100)}
								variant='success'
								isChild={true}
								style={{ 
									borderRadius: 0,
									height: 6,
									transition: 'width 1s linear'
								}}
							></ProgressBar>
						</div>
					</div>
				) : (
					<div>
						<div className='d-flex align-items-center justify-content-start gap-4 ps-3 pe-3 mb-3'>
							<div>
								<svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path 
										fill-rule="evenodd" 
										clip-rule="evenodd" 
										d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z" 
										fill="#dc3545"
									/>
								</svg>
							</div>
							<h5 className='m-0 text-muted'>{guestDetails.message}</h5>
						</div>
						<div 
							style={{ 
								backgroundColor: 'rgba(220, 54, 69, 0.4)',
								borderRadius: 0,
								height: 6,
							}}
						>
							<ProgressBar
								max={100}
								min={0}
								now={((timer / initialPopupTimeout) * 100)}
								variant='danger'
								isChild={true}
								style={{ 
									borderRadius: 0,
									height: 6,
									transition: 'width 1s linear'
								}}
							></ProgressBar>
						</div>
					</div>
				)}
				
				<div className='ps-4 pe-4 mt-4'>
					<div>
						<h4>{(guestDetails.data.guest_name) ? guestDetails.data.guest_name : `${guestDetails.data.first_name || ''} ${guestDetails.data.last_name || ''}`}</h4>
						<p className='text-muted'>{(guestDetails.data.company) ? guestDetails.data.company : ''}</p>
					</div>

					<div className="media">
						<div className="cp_text_bx  media-body">
							<Form onSubmit={e => e.preventDefault()}>
								{/* <Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Status</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.status.replace(/_/, ' ').toUpperCase()}
											disabled={true}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group> */}
								
								<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Guest List</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.data.guest_list?.list_name}
											disabled={true}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group>

								{guestDetails.data.notification && (
								<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Notification</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.data.notification}
											disabled={true}
											style={{
												color: '#dc3545'
											}}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group>
								)}
							</Form>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-dark" onClick={() => openGuestDetails(guestDetails.data.id)} style={{ width: 'auto', flexGrow: 0.3, padding: '0.6rem' }}>
					Guest Details
				</Button>

				<Button variant="dark" onClick={onClose} style={{ width: 'auto', flexGrow: 1.3, padding: '0.6rem' }}>
					Continue
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

const Scanner = ({eventId, onResult, openGuestDetails}: ScannerProps) => {
	const dispatch = useAppDispatch();
	const scanType = useAppSelector(state => state.auth.scanType);
	const deviceName = useAppSelector(state => state.auth.deviceName);
	const [guestDetails, setGuestDetails] = useState<ResponseObject<GuestWithList> | null>(null);

	const isMobile = window.innerWidth <= 768;

	useEffect(() => {
		waitForNextScan = false;
	}, []);

	const onDecode = async (result: string) => {
		if (waitForNextScan) {
			return;
		}
		console.log('onDecode', result)

		if (scanType === ScanTypes.FIND) {
			onResult(result)
		}
		else if (scanType === ScanTypes.CHECK_IN) {
			if (eventId) {

				waitForNextScan = true;
				setTimeout(() => {
					waitForNextScan = false;
				}, 5000)

				const response = await apiSearchAndCheckedInGuest(eventId, {
					q: result,
					device_name: deviceName,
				})
	
				if (response.success || response.data) {
					setGuestDetails(response)
				}
				else {
					toast.error(response.message)
				}
			}
		}
		else if (scanType === ScanTypes.TRACK) {
			if (eventId) {

				waitForNextScan = true;
				setTimeout(() => {
					waitForNextScan = false;
				}, 5000)

				const response = await apiSearchAndTrackGuest(eventId, {
					q: result,
					device_name: deviceName,
				})
	
				if (response.success) {
					let checkGuestName = 'Guest';
					if (response.data.guest_name) {
						checkGuestName = response.data.guest_name;
					}
					else if (response.data.first_name || response.data.last_name) {
						checkGuestName = `${response.data.first_name || ''} ${response.data.last_name || ''}`;
					}

					toast.success(`${checkGuestName} checked`, {
						toastId: response.data.id
					});
				}
				else {
					toast.error(response.message)
				}
			}
		}
	}

	const onError = (error: any) => {
		console.log(error?.message)
	}

	return (
		<div className='' style={{
			// height: 'calc(100vh - 160px)',
			height: '100%',
			background: '#2C292C'
		}}>
			{!guestDetails ? (
			<div style={{
				width: (isMobile) ? window.innerWidth : (window.innerHeight - 120) / 1.4,
				height: (isMobile) ? window.innerWidth : (window.innerHeight - 120) / 1.4,
				// width: 400,
				// height: 400,
				marginLeft: 'auto',
				marginRight: 'auto'
			}}>
				<QrScanner
					onDecode={onDecode}
					onError={onError}
				/>
				
				<div className="d-grid gap-2 mt-4">
					<Button className='text-center' variant='light' onClick={e => dispatch(setShowScanner(false))}>Close Scanner</Button>
					{/* <Button className='text-center' variant='light' onClick={e => onDecode('mattis.integer@hotmail.net')}>Test Check</Button> */}
				</div>
			</div>
			) : (
				<GuestDetailsPopup guestDetails={guestDetails} onClose={() => setGuestDetails(null)} openGuestDetails={openGuestDetails} />
			)}

		</div>
	);
};

export default memo(Scanner);