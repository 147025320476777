import { apiArchiveTemplate, apiDeleteTemplate, apiDuplicateTemplate, apiGetTemplates } from 'api/emailTemplate';
import { EmailTemplateStatuses, EmailTemplateTypes } from 'constant';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { batch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setHeaderTitle } from 'redux/interfaceManger';
import { EmailTemplate } from 'types';
import TemplateCreatePopup from './TemplateCreatePopup';
import TemplateEditPopup from './TemplateEditPopup';

const EmailTemplates = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [templates, setTemplates] = useState<EmailTemplate[]>([]);
	const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
	const [isEditTemplate, setIsEditTemplate] = useState<EmailTemplate | null>(null);
	const [reloadTable, setReloadTable] = useState<number>(0);
	const [confirmArchiveTemplate, setConfirmArchiveTemplate] = useState<EmailTemplate | null>(null);
	const [confirmDeleteTemplate, setConfirmDeleteTemplate] = useState<EmailTemplate | null>(null);
	const [showArchiveTemplates, setShowArchiveTemplates] = useState<boolean>(false);

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Email Templates'))
		})
	}, [])

	useEffect(() => {

		(async () => {
			if (activeEvent) {
				const response = await apiGetTemplates(activeEvent.id)
	
				if (response.success) {
					setTemplates(response.data)
				}
			}
		})();

	}, [activeEvent, reloadTable])

	const onTemplateCreate = (template: EmailTemplate) => {
		if (template.type === EmailTemplateTypes.HTML_UPLOAD) {
			return navigate(`/email-templates/${template.id}/edit-html`, {state: template})
		} else {
			return navigate(`/email-templates/${template.id}/edit-builder`, {state: template})
		}
	}

	const onTemplateUpdate = (template: EmailTemplate) => {
		setIsEditTemplate(null)
		setReloadTable(prev => prev + 1);
	}

	const duplicateTemplate = async (template: EmailTemplate) => {
		if (template && template.id) {
			const response = await apiDuplicateTemplate(template.id);
	
			if (response.success) {
				setReloadTable(prev => prev + 1);
			} else {
				alert(response.message)
			}
		}
	}

	const archiveTemplate = async (template: EmailTemplate) => {
		if (template && template.id) {
			const response = await apiArchiveTemplate(template.id);
	
			if (response.success) {
				setConfirmArchiveTemplate(null)
				setReloadTable(prev => prev + 1);
			} else {
				alert(response.message)
			}
		}
	}

	const deleteTemplate = async (template: EmailTemplate) => {
		if (template && template.id) {
			const response = await apiDeleteTemplate(template.id);
	
			if (response.success) {
				setConfirmDeleteTemplate(null);
				setReloadTable(prev => prev + 1);
			} else {
				alert(response.message)
			}
		}
	}


	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className='page-email-template'>
				<div className='mb-4'>
					<Row>
						<Col>
							<Button variant='dark' onClick={() => setCreatePopupOpen(true)}>
								<i className="ico">
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
								New Template
							</Button>
						</Col>
						<Col className='text-end'>
							<Button variant='outline-secondary' size='lg' active={showArchiveTemplates} onClick={() => setShowArchiveTemplates(!showArchiveTemplates)}><span className='small'>Archived</span></Button>
						</Col>
					</Row>
				</div>
				<div className="page-container">
					<Table responsive borderless>
						<thead>
							<tr>
								<th>Name</th>
								<th style={{ textAlign: 'center' }} >Status</th>
								<th style={{ textAlign: 'center' }} ></th>
								<th style={{ textAlign: 'center' }} >Recipients</th>
								<th>Last Modified</th>
								<th className='cp_btn_bx'></th>
							</tr>
						</thead>
						<tbody>
							{(templates.length === 0) ? (
							<tr>
								<td colSpan={5} className='text-center text-muted'>No template available!</td>
							</tr>
							) : null}

							{templates.filter(template => template.status !== EmailTemplateStatuses.ARCHIVED).map((template, i) => {
								return (
									<tr key={template.id}>
										<td style={{ width: '40%' }}>
											<div className='d-flex align-items-center justify-content-between'>
											<span>{template.name}</span>
											{(template.status !== EmailTemplateStatuses.ACTIVE) ? (
												<Button type='button' variant='white' onClick={() => setIsEditTemplate(template)}>Edit</Button>
											) : null}
											</div>
										</td>
										<td style={{ textAlign: 'center' }}>
											<div 
												className={`status-badge ${(template.status === EmailTemplateStatuses.ACTIVE) ? 'active' : ''} ${(template.status === EmailTemplateStatuses.DRAFT) ? 'testing' : ''} ${(template.status === EmailTemplateStatuses.ARCHIVED) ? 'archived' : ''} `}
												style={{ margin: '0 auto' }}
											>
												<span className="dot"></span>
												<span className="label">
													{template.status === EmailTemplateStatuses.ACTIVE ? 'Active' : template.status === EmailTemplateStatuses.DRAFT ? 'Draft' : 'Archived'}
												</span>
											</div>
										</td>
										<td style={{ textAlign: 'center' }}>
											{(template.status === EmailTemplateStatuses.ACTIVE && template.template !== null) ? (
												<Link to={`/email-templates/${template.id}/report`} state={template} className='text-dark' style={{ display: 'inline' }}>Report</Link>
											) : (
												<>
												{(template.template === null) ? (
													<>
													{(template.type === EmailTemplateTypes.HTML_UPLOAD) ? (
														<Link to={`/email-templates/${template.id}/edit-html`} state={template} className='text-dark' style={{ display: 'inline' }}>Design</Link>
													) : (
														<Link to={`/email-templates/${template.id}/edit-builder`} state={template} className='text-dark' style={{ display: 'inline' }}>Design</Link>
													)}
													</>
												) : (
													<Link to={`/email-templates/${template.id}/preview`} className='text-dark' style={{ display: 'inline' }}>Design</Link>
												)}
												</>
											)}
										</td>
										<td style={{ textAlign: 'center' }}>
											{(template.status === EmailTemplateStatuses.ACTIVE) ? (
												<>{template.totalEmailSent || 0}</>
											) : (
												<>-</>
											)}
										</td>
										<td>
											<div style={{ color: '#aaa' }}>{template.updated_at}</div>
											<div style={{ color: '#aaa' }}>by {template.modified_by?.name}</div>
										</td>
										<td style={{ width: '12%' }}>

											<div style={{
												display: 'flex',
												gap: '5px'
											}}>
												<Button type='button' variant='white' title='Copy'
													onClick={() => duplicateTemplate(template)}
												>
												<svg width="18px" height="18px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
													<path fill="#333333" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"/><path fill="#000000" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"/>
												</svg>
												</Button>

												<Button type='button' variant='white' title='Archive'
													disabled={(template.status === EmailTemplateStatuses.ARCHIVED)}
													onClick={() => setConfirmArchiveTemplate(template)}
												>
													<svg fill="#333333" width="18px" height="18px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
														<path d="M223.15527,68.42188l-16-32A7.99967,7.99967,0,0,0,200,32H56a7.99967,7.99967,0,0,0-7.15527,4.42188l-16,32A7.99934,7.99934,0,0,0,32,72V208a16.01833,16.01833,0,0,0,16,16H208a16.01833,16.01833,0,0,0,16-16V72A7.99934,7.99934,0,0,0,223.15527,68.42188Zm-55.55713,87.29394-33.94091,33.94141c-.0542.0542-.11426.10107-.16944.15332-.1333.126-.26611.25244-.4082.36914-.06689.05517-.13867.10254-.207.15527-.13916.10693-.27686.21484-.42285.313-.05616.0376-.11621.06885-.17334.105-.16358.104-.32813.207-.49951.29883-.04248.023-.08692.041-.12989.0625-.18945.09765-.38135.1914-.57959.27392-.02832.01221-.05859.02051-.0874.03223-.2124.08545-.42725.16455-.64844.23193-.02929.00928-.05957.01465-.08886.02295-.21973.064-.44141.12256-.668.168-.06543.01319-.13282.01856-.19873.03028-.19092.0332-.38184.06689-.57715.08593a7.89257,7.89257,0,0,1-1.59766,0c-.19531-.019-.38623-.05273-.57715-.08593-.06591-.01172-.1333-.01709-.19873-.03028-.22656-.04541-.44824-.104-.668-.168-.02929-.0083-.05957-.01367-.08886-.02295-.22168-.06738-.438-.14746-.65088-.23291-.02783-.01123-.05713-.01953-.085-.03125-.19824-.08252-.39014-.17627-.58008-.27392-.04248-.022-.08692-.03955-.1294-.0625-.17138-.0918-.33593-.19483-.49951-.29883-.05713-.03613-.11718-.06738-.17334-.105-.146-.09815-.28369-.20606-.42285-.313-.06836-.05273-.14014-.1001-.207-.15527-.13818-.11328-.26709-.23731-.39746-.35938-.05908-.05566-.12256-.10547-.18018-.16308L88.40186,155.71582a8.00035,8.00035,0,0,1,11.314-11.31445L120,164.686V104a8,8,0,0,1,16,0v60.686l20.28418-20.28467a8.00035,8.00035,0,0,1,11.314,11.31445ZM52.94434,64l8-16H195.05566l8,16Z"/>
													</svg>
												</Button>

												<Button type='button' variant='white' title='Delete' 
													disabled={(template.status === EmailTemplateStatuses.ACTIVE)}
													onClick={() => setConfirmDeleteTemplate(template)}
												>
													<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
														<path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
															stroke="#333333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
														/>
													</svg>
												</Button>
											</div>
										</td>
									</tr>
								)
							})}
							
							{(showArchiveTemplates) ? (
								<>
								<tr>
									<th colSpan={6} style={{
										color: '#777',
										paddingTop: '2rem',
									}}>Archived Templates</th>
								</tr>

								{templates.filter(template => template.status === EmailTemplateStatuses.ARCHIVED).map((template, i) => {
									return (
										<tr key={template.id}>
											<td style={{ width: '40%' }}>
												<div className='d-flex align-items-center justify-content-between'>
												<span>{template.name}</span>
												{(template.status !== EmailTemplateStatuses.ACTIVE) ? (
													<Button type='button' variant='white' onClick={() => setIsEditTemplate(template)}>Edit</Button>
												) : null}
												</div>
											</td>
											<td style={{ textAlign: 'center' }}>
												<div 
													className={`status-badge ${(template.status === EmailTemplateStatuses.ACTIVE) ? 'active' : ''} ${(template.status === EmailTemplateStatuses.DRAFT) ? 'testing' : ''} ${(template.status === EmailTemplateStatuses.ARCHIVED) ? 'archived' : ''} `}
													style={{ margin: '0 auto' }}
												>
													<span className="dot"></span>
													<span className="label">
														{template.status === EmailTemplateStatuses.ACTIVE ? 'Active' : template.status === EmailTemplateStatuses.DRAFT ? 'Draft' : 'Archived'}
													</span>
												</div>
											</td>
											<td style={{ textAlign: 'center' }}>
												{(template.template === null) ? (
													<Link to={`/email-templates/${template.id}/edit-html`} state={template} className='text-dark' style={{ display: 'inline' }}>Design</Link>
												) : (
													<Link to={`/email-templates/${template.id}/report`} state={template} className='text-dark' style={{ display: 'inline' }}>Report</Link>
												)}
											</td>
											<td style={{ textAlign: 'center' }}>
												{(template.status === EmailTemplateStatuses.ACTIVE) ? (
													<>0</>
												) : (
													<>-</>
												)}
											</td>
											<td>
												<div style={{ color: '#aaa' }}>{template.updated_at}</div>
												<div style={{ color: '#aaa' }}>by {template.modified_by?.name}</div>
											</td>
											<td style={{ width: '12%' }}>
	
												<div style={{
													display: 'flex',
													gap: '5px'
												}}>
													<Button type='button' variant='white' title='Copy'
														onClick={() => duplicateTemplate(template)}
													>
													<svg width="18px" height="18px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
														<path fill="#333333" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"/><path fill="#000000" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"/>
													</svg>
													</Button>
	
													<Button type='button' variant='white' title='Archive'
														disabled={(template.status === EmailTemplateStatuses.ARCHIVED)}
														onClick={() => setConfirmArchiveTemplate(template)}
													>
														<svg fill="#333333" width="18px" height="18px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
															<path d="M223.15527,68.42188l-16-32A7.99967,7.99967,0,0,0,200,32H56a7.99967,7.99967,0,0,0-7.15527,4.42188l-16,32A7.99934,7.99934,0,0,0,32,72V208a16.01833,16.01833,0,0,0,16,16H208a16.01833,16.01833,0,0,0,16-16V72A7.99934,7.99934,0,0,0,223.15527,68.42188Zm-55.55713,87.29394-33.94091,33.94141c-.0542.0542-.11426.10107-.16944.15332-.1333.126-.26611.25244-.4082.36914-.06689.05517-.13867.10254-.207.15527-.13916.10693-.27686.21484-.42285.313-.05616.0376-.11621.06885-.17334.105-.16358.104-.32813.207-.49951.29883-.04248.023-.08692.041-.12989.0625-.18945.09765-.38135.1914-.57959.27392-.02832.01221-.05859.02051-.0874.03223-.2124.08545-.42725.16455-.64844.23193-.02929.00928-.05957.01465-.08886.02295-.21973.064-.44141.12256-.668.168-.06543.01319-.13282.01856-.19873.03028-.19092.0332-.38184.06689-.57715.08593a7.89257,7.89257,0,0,1-1.59766,0c-.19531-.019-.38623-.05273-.57715-.08593-.06591-.01172-.1333-.01709-.19873-.03028-.22656-.04541-.44824-.104-.668-.168-.02929-.0083-.05957-.01367-.08886-.02295-.22168-.06738-.438-.14746-.65088-.23291-.02783-.01123-.05713-.01953-.085-.03125-.19824-.08252-.39014-.17627-.58008-.27392-.04248-.022-.08692-.03955-.1294-.0625-.17138-.0918-.33593-.19483-.49951-.29883-.05713-.03613-.11718-.06738-.17334-.105-.146-.09815-.28369-.20606-.42285-.313-.06836-.05273-.14014-.1001-.207-.15527-.13818-.11328-.26709-.23731-.39746-.35938-.05908-.05566-.12256-.10547-.18018-.16308L88.40186,155.71582a8.00035,8.00035,0,0,1,11.314-11.31445L120,164.686V104a8,8,0,0,1,16,0v60.686l20.28418-20.28467a8.00035,8.00035,0,0,1,11.314,11.31445ZM52.94434,64l8-16H195.05566l8,16Z"/>
														</svg>
													</Button>
	
													<Button type='button' variant='white' title='Delete' 
														disabled={(template.status === EmailTemplateStatuses.ACTIVE)}
														onClick={() => setConfirmDeleteTemplate(template)}
													>
														<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'text-top' }} >
															<path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" 
																stroke="#333333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
															/>
														</svg>
													</Button>
												</div>
											</td>
										</tr>
									)
								})}
								</>
							) : null}
							
						</tbody>
					</Table>
				</div>


				{(createPopupOpen) && (
					<TemplateCreatePopup eventId={activeEvent.id} isOpen={createPopupOpen} setIsOpen={setCreatePopupOpen} onCreated={onTemplateCreate} />
				)}

				{(isEditTemplate) && (
					<TemplateEditPopup template={isEditTemplate} eventId={activeEvent.id} isOpen={isEditTemplate !== null} setIsOpen={setIsEditTemplate} onUpdate={onTemplateUpdate} />
				)}


				{(confirmArchiveTemplate) && (
				<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmArchiveTemplate !== null} onHide={() => setConfirmArchiveTemplate(null)} className=''>
					<Modal.Header closeButton>
						<Modal.Title>Archive Email Template</Modal.Title>
					</Modal.Header>

					<Modal.Body className='text-center'>
						<p>
						Are you sure you want to archive <strong>{confirmArchiveTemplate.name}</strong> template?
						</p>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="outline-dark" onClick={() => setConfirmArchiveTemplate(null)} style={{ flexGrow: 1 }}>
							Cancel
						</Button>

						<Button variant="dark" onClick={() => archiveTemplate(confirmArchiveTemplate)} style={{ flexGrow: 1 }}>
							Yes, archive
						</Button>
					</Modal.Footer>
				</Modal>
				)}

				{(confirmDeleteTemplate) && (
				<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmDeleteTemplate !== null} onHide={() => setConfirmDeleteTemplate(null)} className=''>
					<Modal.Header closeButton>
						<Modal.Title>Delete Email Template</Modal.Title>
					</Modal.Header>

					<Modal.Body className='text-center'>
						<p>
						Are you sure you want to delete <strong>{confirmDeleteTemplate.name}</strong> template?
						</p>

						<p className="mb-2 text-danger"><strong>This cannot be undone</strong></p>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="outline-dark" onClick={() => setConfirmDeleteTemplate(null)} style={{ flexGrow: 1 }}>
							Cancel
						</Button>

						<Button variant="danger" onClick={() => deleteTemplate(confirmDeleteTemplate)} style={{ flexGrow: 1 }}>
							Yes, delete
						</Button>
					</Modal.Footer>
				</Modal>
				)}
			</div>
		</>
	);
};

export default EmailTemplates;