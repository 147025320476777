import { DomainEmails, EmailTemplate, ResponseObject, TemplateEmailResponse, TemplateEmailSummary } from "types"
import apiService from "services/ApiService";
import cacheService from "services/CacheService";
import { IToken } from "@mailupinc/bee-plugin/dist/types/bee";

type QueryParams = {
	page: number,
	limit: number,
	orderBy: string,
	orderType: string,
	q?: string
}

export const apiGetTemplates = async (
	eventId: string | number,
): Promise<ResponseObject<EmailTemplate[]>> => {

	return await apiService.get(`/email-template/${eventId}`);
}

export const apiGetEmailSenders = async (
	eventId: string | number,
): Promise<ResponseObject<DomainEmails[]>> => {

	return await apiService.get(`/email-template/get-senders/${eventId}`);
}

export const apiGetTemplatePreview = async (
	eventId: string | number,
	templateId: string | number,
	guestId?: string | number | null,
): Promise<ResponseObject<EmailTemplate>> => {

	return await apiService.get(`/email-template/preview/${eventId}/${templateId}`, {
		guest_id: (guestId) ? guestId : null
	});
}

export const apiCreateTemplate = async (
	eventId: string | number,
	data: EmailTemplate
): Promise<ResponseObject<EmailTemplate>> => {
	return await apiService.postMultipart(`/email-template/create/${eventId}`, {
		...data
	});
}

export const apiUpdateTemplate = async (
	eventId: string | number,
	templateId: string | number,
	data: EmailTemplate
): Promise<ResponseObject<EmailTemplate>> => {
	return await apiService.postMultipart(`/email-template/update/${eventId}/${templateId}`, {
		...data
	});
}


export const apiUploadTemplate = async (
	eventId: string | number,
	templateId: string | number,
	file: any
): Promise<ResponseObject<EmailTemplate>> => {
	return await apiService.postMultipart(`/email-template/upload/${eventId}/${templateId}`, {
		file
	});
}


export const apiDuplicateTemplate = async (
	templateId: string | number,
): Promise<ResponseObject<EmailTemplate>> => {
	return await apiService.post(`/email-template/duplicate/${templateId}`, {});
}

export const apiArchiveTemplate = async (
	templateId: string | number,
): Promise<ResponseObject<EmailTemplate>> => {
	return await apiService.post(`/email-template/archive/${templateId}`, {});
}

export const apiDeleteTemplate = async (
	templateId: string | number,
): Promise<ResponseObject<null>> => {
	return await apiService.delete(`/email-template/delete/${templateId}`);
}


export const apiSendTestEmails = async (
	eventId: string | number,
	templateId: string | number,
	guestId: string | number,
	emails: string[],
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/email-template/test-emails/${eventId}/${templateId}`, {
		guestId,
		emails,
	});
}

export const apiEmailSummaryReport = async (
	eventId: string | number,
	templateId: string | number,
): Promise<ResponseObject<TemplateEmailSummary>> => {

	return await apiService.get(`/email-template/report/${eventId}/${templateId}/summary`);
}

export const apiEmailReport = async (
	eventId: string | number,
	templateId: string | number,
	{
		page = 1,
		limit = 10,
		orderBy = 'id',
		orderType = 'DESC',
		q
	}: QueryParams
): Promise<ResponseObject<TemplateEmailResponse>> => {

	return await apiService.get(`/email-template/report/${eventId}/${templateId}/emails`, {
		page,
		limit,
		orderBy,
		orderType,
		q
	});
}

export const apiDownloadEmailReport = async (
	eventId: string | number,
	templateId: string | number,
): Promise<ResponseObject<string>> => {

	return await apiService.get(`/email-template/report/${eventId}/${templateId}/download`);
}

export const apiGetBeeFreeToken = async (): Promise<ResponseObject<IToken>> => {

	return await apiService.get(`/email-template/beefree/token`);
}

export const saveBuilderData = async (
	eventId: string | number,
	templateId: string | number,
	json: any,
	html: any
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/email-template/save-builder-data/${eventId}/${templateId}`, {
		json,
		html
	});
}